#sku_params_body {
    background: radial-gradient(47.4% 47.4% at 50% 51.71%, #27214B 0%, #170C24 100%);
}

#sku_params_heading {
    background: #DB2349;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font: 700 48px/57.6px "Poppins";
    margin-top: 4rem;
    text-align: center;
    color: white;
}

.sku_paramsTable {
    border-collapse: collapse;
    width: 95% !important;
    margin: 2rem auto !important;
    background: linear-gradient(180deg, #1E1637 -8.36%, #1C1230 145.07%);
    border-radius: 16px !important;
    border: 2px solid rgb(57, 50, 100) !important;
    padding-bottom: 20px !important;
}

.sku_paramsTable td,
.sku_paramsTable th {
    border: 1px solid rgb(57, 50, 100);
    background: transparent;
    color: white !important;
}

.sku_paramsTable td {
    padding: 15px 15px !important;
    font: 400 20px/28px "Poppins" !important;
    text-align: center !important;
    vertical-align: middle !important;
}

.sku_params_tbl_headers {
    font: 600 24px/36px "Poppins" !important;
    color: #FFF !important;
    text-align: center !important;
    vertical-align: middle !important;
    padding: 20px 20px !important;
}

@media screen and (max-width: 450px) {
    #sku_params_heading {
        font: 600 24px/36px "Poppins" !important;
    }
}
